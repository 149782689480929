<template>
  <div class="">
    <Header />
    <div class="container">
      <h2>Ploutus Staff Forms</h2>
      <div class="forms-holder">
        <div>
          <a href="https://staging.ploutus.com/profile/Leave-Request-Form.doc" class="no-decor" target="_blank">
            <img src="@/assets/leave-req-form.jpg" alt="Form 1" /><br>
            Leave Request Form
          </a>
        </div>
        <div>
          <a href="https://staging.ploutus.com/profile/Travel-Request-Form.docx" class="no-decor" target="_blank">
            <img src="@/assets/travel-req-form.jpg" alt="Form 2" /><br>
            Travel Request Form
          </a>
        </div>
        <div>
          <a href="https://staging.ploutus.com/profile/Ploutus-SOP.pdf" class="no-decor" target="_blank">
            <img src="@/assets/hr-sop.jpg" alt="Form 3" /><br>
            HR SOP Form
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  components: {Header},
  setup() {
    
  },
}
</script>
<style>
.forms-holder{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.forms-holder img{
  width: 200px;
  height: 250px;
}
</style>

